import React from "react";
import Airtel from "../Assets/airtel.png";
import Indus from "../Assets/indus.png";
import SolutionStep from "./SolutionStep";
import "../Styles/Projects.css";
import styled from "styled-components";
import Header from "./Navbar";
import Navbar from "./NavDrop";
import Footer from "./Footer";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
function About() {
  return (
    <Container id="Projects">
      <Header />
      <Navbar />

      <div className="about-section" id="about">
        <div className="project-image-contentleft">
          <img src={Airtel} alt="project1" className="about-image1" />
        </div>

        <div className="about-text-content">
          <h3 className="project-title">
            <span>About Our Airtel Project</span>
          </h3>
          <p className="about-description">
            Welcome to our esteemed Project Management Consultancy (PMC) firm,
            where excellence in overseeing diverse infrastructure projects meets
            a commitment to delivering comprehensive solutions. Our expertise
            spans various domains, including: Telecom: We specialize in civil
            and electrical construction for telecom networks. Interior Work: Our
            team ensures seamless synchronization in creating functional and
            aesthetically pleasing interior spaces. Retail Showrooms: Feel free
            to reach out if you need further assistance or have any other
            requests! If you’d like any further adjustments or have additional
            information to include, please let me know!
          </p>

          {/* <h4 className="about-text-title">Our Vision</h4> */}

          <SolutionStep
            title="Our Management Techniques"
            description="Consistency: Maintain consistent schedules, expectations, and interactions with your team.
Openness to Ideas: Listen to feedback and encourage new ideas from employees.
Build Strong Teams: Consider skills and personalities when hiring to foster positive working relationships.
Effective Delegation: Trust team members with responsibilities.
Recognition: Acknowledge and appreciate team efforts.
Leverage Technology: Use tools to streamline processes.
Communication and Feedback: Regularly communicate and provide feedback to align everyone with organizational goals.
"
          />

          <SolutionStep
            title="Our Solutions"
            description="Enabling communication for millions of people
helping to providing high quality and relevant
services for teelcom network service providers."
          />
        </div>
      </div>
      <div className="about-section">
        <div className="about-text-content">
          <h3 className="project-title2">
            <span>About Our Indus Tower Project</span>
          </h3>
          <p className="about-description">
            At our esteemed Project Management Consultancy (PMC) firm, we have
            successfully overseen several Indus Tower projects. Our expertise in
            telecom infrastructure includes: Site Acquisition and Planning: We
            handle site selection, acquisition, and planning for Indus Towers.
            Tower Construction: Our team ensures efficient and safe tower
            construction, adhering to industry standards. Electrical
            Installations: We manage electrical installations, ensuring reliable
            power supply to telecom towers. Maintenance and Upgrades: Our
            services extend to tower maintenance and technology upgrades. Our
            commitment to operational excellence and client satisfaction remains
            unwavering. Feel free to reach out if you need further assistance or
            have any other requests! If you’d like any additional details or
            have specific points to highlight, please let me know!
          </p>

          {/* <h4 className="about-text-title">Our Vision</h4> */}

          <SolutionStep
            title="Our Management Techniques"
            description="Consistency: Maintain consistent schedules, expectations, and interactions with your team.
Openness to Ideas: Listen to feedback and encourage new ideas from employees.
Build Strong Teams: Consider skills and personalities when hiring to foster positive working relationships.
Effective Delegation: Trust team members with responsibilities.
Recognition: Acknowledge and appreciate team efforts.
Leverage Technology: Use tools to streamline processes.
Communication and Feedback: Regularly communicate and provide feedback to align everyone with organizational goals.
"
          />

          <SolutionStep
            title="Our Solutions"
            description="Enabling communication for millions of people
helping to providing high quality and relevant
services for teelcom network service providers."
          />
        </div>
        <div className="project-image-contentright">
          <img src={Indus} alt="Doctor Group" className="about-image1" />
        </div>
      </div>

      <Footer />
    </Container>
  );
}

export default About;
