import React from "react";
import Header from "./Navbar";
import Navbar from "./NavDrop";
import Footer from "./Footer";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  padding: 32px;
  text-align: center;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 480px;
  margin-top: 32px;
  display: flex;
  justify-content: center;

  iframe {
    border: 10px;
    width: 100%;
    max-width: 980px;
  }

  @media (max-width: 768px) {
    iframe {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`;

function MapEmbed() {
  return (
    <Container>
      <Header />
      <Navbar />
      <Content>
        <h1
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            borderRadius: "10px",
            textDecoration: "underline",
            padding: "10px",
          }}
        >
          Total Sites Completed by Us
        </h1>
        <MapContainer>
          <iframe
            src="https://www.google.com/maps/d/embed?mid=1DA_emY-VYjeh__ZYRU67uIinGdKll5o&ehbc=2E312F"
            height="480"
                      allowFullScreen=""
                      
            loading="lazy"
            title="Geotagging of Total Sites by Veritech"
          ></iframe>
        </MapContainer>
      </Content>
      <Footer />
    </Container>
  );
}

export default MapEmbed;
