import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Company from "../src/Components/Company";
import Contact from "../src/Components/Contact";
import Projects from "../src/Components/Projects";
import AirtelProject from "../src/Components/Airtel";
import IndusProject from "../src/Components/Indus";
import TotalSites from "../src/Components/TotalSites";
import Home from "./Pages/Home";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/AirtelProject" element={<AirtelProject />} />
          <Route path="/IndusProject" element={<IndusProject />} />
          <Route path="/TotalSites" element={<TotalSites />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
