
// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faLinkedin,
//   faFacebook,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import Company from "./Company";
// import {
//   faCommentDots,
//   faBars,
//   faXmark,
// } from "@fortawesome/free-solid-svg-icons";
// import "../Styles/Navbar.css";
// import logo from "../Assets/LOGOveritech.png";
// import { Link, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
// import { HashLink } from "react-router-hash-link";
// import styled from "styled-components";
// const Dropdown = styled.div`
//   position: relative;
//   display: inline-block;
//   width: 100%;
// `;

// const DropdownContent = styled.div`
//   display: ${(props) => (props.show ? "block" : "none")};
  
//   background-color: whitesmoke;
 
//   box-shadow: whitesmoke;
//   z-index: 1;
//   width: 100%;

//   a {
//     color: whitesmoke;
//     padding: 12px 16px;
//     text-decoration: none;
//     display: block;
//     background-color: whitesmoke;

//     &:hover {
//       background-color: whitesmoke;
//     }
//   }
// `;

// function Navbar() {
//   const [nav, setNav] = useState(false);
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const location = useLocation();

//   const openNav = () => {
//     setNav(!nav);
//   };

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };
// const [clickCount, setClickCount] = useState(0);
// const handleCompanyClick = (event) => {
//   event.stopPropagation();
//   setClickCount((prevCount) => {
//     const newCount = prevCount + 1;
//     if (newCount === 2) {
//       toggleDropdown(event);
//       return 0;
//     }
//     return newCount;
//   });
// };

//   const closeNav = () => {
//     setNav(false);
//   };

//   return (
//     <div className="navbar-section">
//       <div className="navbar-title">
//         <Link to="/">
//           <img
//             src={logo}
//             alt="Logo"
//             className="navbar-logo"
//             style={{ width: "80px", height: "70px" }}
//           />
//         </Link>
//         <div className="navbar-sign">
//           <span>VERITECH INFRASTRUCTURE</span>
//           <br />
//           <span>PRIVATE LIMITED</span>
//         </div>
//       </div>

//       {/* Desktop */}
//       {/* <ul className="navbar-items">
//         <li>
//           <Link to="/" className="navbar-links">
//             Home
//           </Link>
//         </li>
//         <li>
//           <div className="dropdown">
//             <a className="navbar-links">
//               Company{" "}
//               <span
//                 style={{
//                   color: "skyblue",
//                   display: "inline-block",
//                   transform: "rotate(90deg)",
//                 }}
//               >
//                 &gt;
//               </span>
//             </a>
//             <div className="dropdown-content">
//               <Link
//                 to="/Company "
//                 className={location.pathname === "/Company " ? "active" : ""}
//               >
//                 About Us
//               </Link>
//               <Link
//                 to="/Contact "
//                 className={location.pathname === "/Contact " ? "active" : ""}
//               >
//                 Contact Us
//               </Link>
//               <Link
//                 to="/Projects "
//                 className={location.pathname === "/Projects " ? "active" : ""}
//               >
//                 Our Projects
//               </Link>
//             </div>
//           </div>
//         </li>
//         <li>
//           <HashLink className="navbar-links" to="/#Services">
//             Services
//           </HashLink>
          
//         </li>
//         <li>
//           <HashLink className="navbar-links" to="/#team">
//             Team
//           </HashLink>
//         </li>
//         <li>
//           <HashLink className="navbar-links" to="/#clients">
//             Clients
//           </HashLink>
        
//         </li>
//       </ul> */}

//       <div className="navbar-media">
//         <a
//           href="https://www.linkedin.com/in/rakesh-kumar-fie-357a8727/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <FontAwesomeIcon icon={faLinkedin} className="navbar-btn" />
//         </a>

//         <a
//           href="https://www.facebook.com"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <FontAwesomeIcon icon={faFacebook} className="navbar-btn" />
//         </a>

//         <a href="tel:1234567890">
//           <FontAwesomeIcon icon={faPhone} className="navbar-btn" />
//         </a>
//       </div>

//       {/* Mobile */}
//       <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
//         <div onClick={openNav} className="mobile-navbar-close">
//           <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
//         </div>

//         <ul className="mobile-navbar-links">
//           <li>
//             <Link onClick={closeNav} to="/">
//               Home
//             </Link>
//           </li>
//           <li>
//             <Dropdown>
//               <a onClick={toggleDropdown}>
//                 Company{" "}
//                 <span
//                   style={{
//                     color: "skyblue",
//                     display: "inline-block",
//                     transform: "rotate(90deg)",
//                   }}
//                 >
//                   &gt;
//                 </span>
//               </a>
//               <DropdownContent show={dropdownOpen}>
//                 <Link
//                   to="/Company "
//                   onClick={closeNav}
//                   className={location.pathname === "/Company " ? "active" : ""}
//                 >
//                   About Us
//                 </Link>
//                 <Link
//                   to="/Contact "
//                   onClick={closeNav}
//                   className={location.pathname === "/Contact " ? "active" : ""}
//                 >
//                   Contact Us
//                 </Link>
//                 <Link
//                   to="/Projects "
//                   onClick={closeNav}
//                   className={location.pathname === "/Projects " ? "active" : ""}
//                 >
//                   Our Projects
//                 </Link>
//               </DropdownContent>
//             </Dropdown>
//           </li>
//           <li>
//             <HashLink to="/#services" onClick={closeNav}>
//               Services
//             </HashLink>
//           </li>
//           <li>
//             <HashLink to="/#team" onClick={closeNav}>
//               Our Team
//             </HashLink>
//           </li>
//         </ul>
//       </div>

//       {/* Hamburger Icon */}
//       <div className="mobile-nav">
//         <FontAwesomeIcon
//           icon={faBars}
//           onClick={openNav}
//           className="hamb-icon"
//         />
//       </div>
//     </div>
//   );
// }

// export default Navbar;


import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import logo from "../Assets/LOGOveritech.png";
import "../Styles/Navbar.css";

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: whitesmoke;
  box-shadow: whitesmoke;
  z-index: 1;
  width: 100%;

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: whitesmoke;

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

function Navbar() {
  const [nav, setNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bdropdownOpen, setbDropdownOpen] = useState(false);
  const [pdropdownOpen, setpDropdownOpen] = useState(false);
  const location = useLocation();

  const openNav = () => {
    setNav(!nav);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
     if (bdropdownOpen) {
       setbDropdownOpen(false);
    }
    if(pdropdownOpen) {
      setpDropdownOpen(false);
    }
  };
   const btoggleDropdown = () => {
     setbDropdownOpen(!bdropdownOpen);
      if (dropdownOpen) {
        setDropdownOpen(false);
     }
     if(pdropdownOpen) {
       setpDropdownOpen(false);
     }
  };
   const ptoggleDropdown = () => {
     setpDropdownOpen(!pdropdownOpen);
     if (dropdownOpen) {
       setDropdownOpen(false);
     }
     if(bdropdownOpen) {
       setbDropdownOpen(false);
     }
   };

  const closeNav = () => {
    setNav(false);
  };

  return (
    <div className="navbar-section">
      <div className="navbar-title">
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
            className="navbar-logo"
            style={{ width: "80px", height: "70px" }}
          />
        </Link>
        <div className="navbar-sign">
          <span>VERITECH INFRASTRUCTURE</span>
          <br />
          <span>PRIVATE LIMITED</span>
        </div>
      </div>

      <div className="navbar-media">
        {/* <a
          href="https://www.linkedin.com/in/rakesh-kumar-fie-357a8727/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} className="navbar-btn" />
        </a>
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} className="navbar-btn" />
        </a> */}
        <a href="tel:1234567890">
          <FontAwesomeIcon icon={faPhone} className="navbar-btn" />
        </a>
        <span className="navbar-text">+91-9934049707 </span>
        <a href="mailto:info@veritech.com">
          <FontAwesomeIcon icon={faEnvelope} className="navbar-btn" />
        </a>
        <span className="navbar-text">info@veritechinfra.com </span>
      </div>

      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>
        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={closeNav} to="/">
              Home
            </Link>
          </li>
          <li>
            <Dropdown>
              <a onClick={toggleDropdown}>
                Company{" "}
                <span
                  style={{
                    color: "skyblue",
                    display: "inline-block",
                    transform: "rotate(90deg)",
                  }}
                >
                  &gt;
                </span>
              </a>
              <DropdownContent show={dropdownOpen}>
                <Link
                  to="/Company "
                  onClick={closeNav}
                  className={location.pathname === "/Company " ? "active" : ""}
                >
                  About Us
                </Link>
                <Link
                  to="/Contact "
                  onClick={closeNav}
                  className={location.pathname === "/Contact " ? "active" : ""}
                >
                  Contact Us
                </Link>
                <Link
                  to="/Projects "
                  onClick={closeNav}
                  className={location.pathname === "/Projects " ? "active" : ""}
                >
                  Our Projects
                </Link>
              </DropdownContent>
            </Dropdown>
          </li>
          <li>
            <Dropdown>
              <a onClick={btoggleDropdown}>
                Our Business{" "}
                <span
                  style={{
                    color: "skyblue",
                    display: "inline-block",
                    transform: "rotate(90deg)",
                  }}
                >
                  &gt;
                </span>
              </a>
              <DropdownContent show={bdropdownOpen}>
                <HashLink to="/#services" onClick={closeNav}>
                  Overview
                </HashLink>
                <HashLink to="/#services" onClick={closeNav}>
                  Services
                </HashLink>
                <HashLink to="/#clients" onClick={closeNav}>
                  Clients
                </HashLink>
              </DropdownContent>
            </Dropdown>
          </li>
          <li>
            <Dropdown>
              <a onClick={ptoggleDropdown}>
                Our Projects{" "}
                <span
                  style={{
                    color: "skyblue",
                    display: "inline-block",
                    transform: "rotate(90deg)",
                  }}
                >
                  &gt;
                </span>
              </a>
              <DropdownContent show={pdropdownOpen}>
                <HashLink to="/TotalSites" onClick={closeNav}>
                  Total Sites
                </HashLink>
                <HashLink to="/AirtelProject" onClick={closeNav}>
                  Airtel Project
                </HashLink>
                <HashLink to="/IndusProject" onClick={closeNav}>
                  Indus Project
                </HashLink>
              </DropdownContent>
            </Dropdown>
          </li>
          <li>
            <HashLink to="/#services" onClick={closeNav}>
              Investors
            </HashLink>
          </li>
          <li>
            <HashLink to="/#team" onClick={closeNav}>
              Media
            </HashLink>
          </li>
          <li>
            <HashLink to="/#team" onClick={closeNav}>
              Carrer
            </HashLink>
          </li>
          <li>
            <HashLink to="/Contact" onClick={closeNav}>
              Contact Us
            </HashLink>
          </li>
        </ul>
      </div>

      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;

