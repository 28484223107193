import React from "react";
import Airtel from "../Assets/airtel.png";
import Indus from "../Assets/indus.png";
import SolutionStep from "./SolutionStep";
import "../Styles/Projects.css";
import styled from "styled-components";
import Header from "./Navbar";
import Navbar from "./NavDrop";
import Footer from "./Footer";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SiteDataContainer = styled.div`
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  width: 100%;
`;

const SiteDataItem = styled.div`
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: 1 1 calc(20% - 32px);
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 16px;
`;

function About() {
  const siteData = [
    { district: "Araria", towers: 3 },
    { district: "Aurangabad", towers: 2 },
    { district: "Bhojpur", towers: 6 },
    { district: "Bokaro", towers: 4 },
    { district: "Buxar", towers: 2 },
    { district: "Darbhanga", towers: 47 },
    { district: "Deoghar", towers: 1 },
    { district: "Dumka", towers: 2 },
    { district: "East Champaran", towers: 23 },
    { district: "Gaya", towers: 6 },
    { district: "Gopalganj", towers: 3 },
    { district: "Jamtara", towers: 1 },
    { district: "Jhenabad", towers: 2 },
    { district: "Kaimur", towers: 1 },
    { district: "Madhubani", towers: 33 },
    { district: "Muzaffarpur", towers: 9 },
    { district: "Nawada", towers: 1 },
    { district: "Paschim champaran", towers: 3 },
    { district: "Patna", towers: 7 },
    { district: "Purbi champaran", towers: 2 },
    { district: "Ranchi", towers: 1 },
    { district: "Rohtas", towers: 6 },
    { district: "Sahebganj", towers: 2 },
    { district: "Samastipur", towers: 22 },
    { district: "Saran", towers: 3 },
    { district: "Seraikela", towers: 4 },
    { district: "Sheikhpura", towers: 1 },
    { district: "Sheohar", towers: 3 },
    { district: "Simdega", towers: 1 },
    { district: "Sitamadhi", towers: 16 },
    { district: "Siwan", towers: 5 },
    { district: "Supaul", towers: 7 },
    { district: "Vaishali", towers: 1 },
    { district: "West Champaran", towers: 19 },
  ];
  siteData.sort((a, b) => b.towers - a.towers);

  return (
    <Container id="Projects">
      <Header />
      <Navbar />

      <div className="about-section" id="about">
        <div className="project-image-contentleft">
          <img src={Airtel} alt="project1" className="about-image1" />
        </div>

        <div className="about-text-content">
          <h3 className="project-title">
            <span>About Our Airtel Project</span>
          </h3>
          <p className="about-description">
            Welcome to our esteemed Project Management Consultancy (PMC) firm,
            where excellence in overseeing diverse infrastructure projects meets
            a commitment to delivering comprehensive solutions. Our expertise
            spans various domains, including: Telecom: We specialize in civil
            and electrical construction for telecom networks. Interior Work: Our
            team ensures seamless synchronization in creating functional and
            aesthetically pleasing interior spaces. Retail Showrooms: Feel free
            to reach out if you need further assistance or have any other
            requests! If you’d like any further adjustments or have additional
            information to include, please let me know!
          </p>

          {/* <h4 className="about-text-title">Our Vision</h4> */}

          <SolutionStep
            title="Our Management Techniques"
            description="Consistency: Maintain consistent schedules, expectations, and interactions with your team.
Openness to Ideas: Listen to feedback and encourage new ideas from employees.
Build Strong Teams: Consider skills and personalities when hiring to foster positive working relationships.
Effective Delegation: Trust team members with responsibilities.
Recognition: Acknowledge and appreciate team efforts.
Leverage Technology: Use tools to streamline processes.
Communication and Feedback: Regularly communicate and provide feedback to align everyone with organizational goals.
"
          />

          <SolutionStep
            title="Our Project Overview"
            description="Our Airtel project spans multiple districts more than 30 districts including bihar and jharkhand, with significant involvement in the following top 5 districts:

Darbhanga: 47 projects
Madhubani: 33 projects
East Champaran: 23 projects
Samastipur: 22 projects
West Champaran: 19 projects"
          />
        </div>
      </div>
      <SiteDataContainer>
        {siteData.map((item, index) => (
          <SiteDataItem key={index}>
            <strong>District : {item.district}</strong>
            <div>no of sites : {item.towers}</div>
          </SiteDataItem>
        ))}
      </SiteDataContainer>
      <Footer />
    </Container>
  );
}

export default About;
