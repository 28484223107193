import React from "react";
import styled from "styled-components";
import Header from "./Navbar";
import Navbar from "./NavHero";
import Footer from "./Footer";
import Road from "../Assets/road.jpg";
import Pitch from "../Assets/VERITECH_pitchdeck.pdf";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

const FullScreenImage = styled.div`
  background-image: url(${Road});
  background-size: cover;
  background-position: center;
  height: 65vh;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-bottom: 25px;

  @media (max-width: 700px) {
    justify-content: center;
   margin-right: 0px;
  }
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f7ef5b;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: black;
    color: #fff;
  }

  @media (max-width: 700px) {
    text-align: center;
  }
`;

const Title = styled.h1`
  position: relative;
  color: white;
  left: 1rem;
  top: 52vh;
  font-size: 45px;
  font-family: ui-sans-serif;
  width: fit-content;
  text-decoration: underline;
  text-decoration-color: yellow;

  @media (max-width: 768px) {
    font-size: 37px;
    margin-bottom: -20px;
  }
`;

const AboutSection = styled.section`
  padding: 20px 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 50px 50px;
  }

  h2 {
    font-size: 2em;
    font-family: ui-sans-serif;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    font-family: ui-sans-serif;
    line-height: 1.6;
    margin-bottom: 20px;
  }
`;

const AboutUs = () => {
  return (
    <Container id="Company">
      <Header />
      <div className="relative">
        <Navbar />
        <Content>
          <FullScreenImage>
            <Title>About Our Company</Title>
          </FullScreenImage>
          <AboutSection>
            <p>
              Welcome to Veritech Infrastructure Private Limited Company, a
              leader in providing innovative and sustainable infrastructure
              solutions. Our mission is to build and maintain the structures
              that support our communities, enhancing the quality of life and
              promoting economic growth.
            </p>
            <p>
              With decades of experience and a commitment to excellence, we
              specialize in a wide range of services including construction,
              engineering, and project management. Our team of dedicated
              professionals is equipped with the expertise and resources to
              handle projects of all sizes and complexities.
            </p>
            <p>
              At Our Infrastructure Company, we prioritize safety,
              sustainability, and customer satisfaction. We strive to exceed
              expectations and deliver results that stand the test of time.
            </p>
          </AboutSection>
        </Content>
        <DownloadButtonContainer>
          <DownloadButton href={Pitch} download>
            Download Our Brochure
          </DownloadButton>
        </DownloadButtonContainer>
        <Footer />
      </div>
    </Container>
  );
};

export default AboutUs;
