import React from "react";
import styled from "styled-components";
import Header from "./Navbar";
import Navbar from "./NavDrop";
import Footer from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  align-items: center;
  font-family: ui-sans-serif;
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
  padding-bottom: 50px;
`;

const PageHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;



const TextOne = styled.b`
  font-size: 30px;
  font-family: ui-sans-serif;
  color: rgb(4, 4, 59);
  text-align: center;
`;

const TextTwo = styled.p`
  color: rgb(4, 4, 34);
  font-family: ui-sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
`;
const FormContainer = styled.div`
  width: 70%;
  background-color: #fff;
  padding: 18px;
  border-radius: 5px;
  height: 78vh;

  @media (max-width: 768px) {
    width: 90%;
    height: 60vh;
  }
`;

const FormField = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;

  @media screen {
    
    
  }max-width: 768px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  display: flex;
  /* margin: auto; */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: grey;
    color: white;
  }
`;

const handleSubmit = (event) => {
  event.preventDefault();
  // Your existing form submission code
  toast.success("Your response has been submitted!");
};

const FormPage = () => {
    return (
      <Container>
        <Header />
        <Navbar />
        <PageWrapper>
          <PageHeadingWrapper>
            <TextOne>Business Enquiry</TextOne>
            <TextTwo>Any query ? Just write us a message </TextTwo>
          </PageHeadingWrapper>
          <FormContainer>
            <FormField type="text" placeholder="Name" />
            <FormField type="tel" placeholder="Phone Number" />
            <FormField type="email" placeholder="Email" />
            <FormField as="textarea" rows="4" placeholder="Subject" />
            <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
            <ToastContainer />
          </FormContainer>
        </PageWrapper>
        <Footer />
      </Container>
    );
};

export default FormPage;