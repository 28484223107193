import React from "react";
import Airtel from "../Assets/airtel.png";
import Indus from "../Assets/indus.png";
import SolutionStep from "./SolutionStep";
import "../Styles/Projects.css";
import styled from "styled-components";
import Header from "./Navbar";
import Navbar from "./NavDrop";
import Footer from "./Footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SiteDataContainer = styled.div`
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  width: 100%;
`;

const SiteDataItem = styled.div`
  
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: 1 1 calc(20% - 32px);
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 16px;
`;

function About() {
  const siteData = [
    { district: "Araria", towers: 3 },
    { district: "Aurangabad", towers: 2 },
    { district: "Bhojpur", towers: 6 },
    { district: "Bokaro", towers: 4 },
    { district: "Buxar", towers: 2 },
    { district: "Darbhanga", towers: 47 },
    { district: "Deoghar", towers: 1 },
    { district: "Dumka", towers: 2 },
    { district: "East Champaran", towers: 23 },
    { district: "Gaya", towers: 6 },
    { district: "Gopalganj", towers: 3 },
    { district: "Jamtara", towers: 1 },
    { district: "Jhenabad", towers: 2 },
    { district: "Kaimur", towers: 1 },
    { district: "Madhubani", towers: 33 },
    { district: "Muzaffarpur", towers: 9 },
    { district: "Nawada", towers: 1 },
    { district: "Paschim champaran", towers: 3 },
    { district: "Patna", towers: 7 },
    { district: "Purbi champaran", towers: 2 },
    { district: "Ranchi", towers: 1 },
    { district: "Rohtas", towers: 6 },
    { district: "Sahebganj", towers: 2 },
    { district: "Samastipur", towers: 22 },
    { district: "Saran", towers: 3 },
    { district: "Seraikela", towers: 4 },
    { district: "Sheikhpura", towers: 1 },
    { district: "Sheohar", towers: 3 },
    { district: "Simdega", towers: 1 },
    { district: "Sitamadhi", towers: 16 },
    { district: "Siwan", towers: 5 },
    { district: "Supaul", towers: 7 },
    { district: "Vaishali", towers: 1 },
    { district: "West Champaran", towers: 19 },
  ];
  siteData.sort((a, b) => b.towers - a.towers);

  return (
    <Container id="IndusProject">
      <Header />
      <Navbar />
      <div className="about-section">
        <div className="about-text-content">
          <h3 className="project-title2">
            <span>About Our Indus Tower Project</span>
          </h3>
          <p className="about-description">
            At our esteemed Project Management Consultancy (PMC) firm, we have
            successfully overseen several Indus Tower projects. Our expertise in
            telecom infrastructure includes: Site Acquisition and Planning: We
            handle site selection, acquisition, and planning for Indus Towers.
            Tower Construction: Our team ensures efficient and safe tower
            construction, adhering to industry standards. Electrical
            Installations: We manage electrical installations, ensuring reliable
            power supply to telecom towers. Maintenance and Upgrades: Our
            services extend to tower maintenance and technology upgrades. Our
            commitment to operational excellence and client satisfaction remains
            unwavering. Feel free to reach out if you need further assistance or
            have any other requests! If you’d like any additional details or
            have specific points to highlight, please let me know!
          </p>
          <SolutionStep
            title="Our Management Techniques"
            description="Consistency: Maintain consistent schedules, expectations, and interactions with your team.
            Openness to Ideas: Listen to feedback and encourage new ideas from employees.
            Build Strong Teams: Consider skills and personalities when hiring to foster positive working relationships.
            Effective Delegation: Trust team members with responsibilities.
            Recognition: Acknowledge and appreciate team efforts.
            Leverage Technology: Use tools to streamline processes.
            Communication and Feedback: Regularly communicate and provide feedback to align everyone with organizational goals."
          />
          <SolutionStep
            title="Our Project Overview"
            description="Our Airtel project spans multiple districts, with significant involvement in the state Bihar and Jharkhand."
          />
        </div>
        <div className="project-image-contentright">
          <img src={Indus} alt="Doctor Group" className="about-image1" />
        </div>
      </div>

      <SiteDataContainer>
        {siteData.map((item, index) => (
          <SiteDataItem key={index}>
            <strong>District : {item.district}</strong>
            <div>no of sites : {item.towers}</div>
          </SiteDataItem>
        ))}
      </SiteDataContainer>

      <Footer />
    </Container>
  );
}

export default About;
