// import React, { useEffect, useState } from "react";
// import Doctor from "../Assets/civil.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faContactBook, faAngleUp } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
// import "../Styles/Hero.css";
// import Footer from "./Footer";

// function Hero() {
//   const navigate = useNavigate();
//   const [goUp, setGoUp] = useState(false);

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const Footerjs = () => {
//     document.getElementById(Footer).scrollIntoView({ behavior: "smooth" });
//   };

//   useEffect(() => {
//     const onPageScroll = () => {
//       if (window.scrollY > 600) {
//         setGoUp(true);
//       } else {
//         setGoUp(false);
//       }
//     };
//     window.addEventListener("scroll", onPageScroll);

//     return () => {
//       window.removeEventListener("scroll", onPageScroll);
//     };
//   }, []);

//   return (
//     <div className="section-container">
//       <div className="hero-section">
//         <div className="text-section">
//           <p className="text-headline"></p>
//           <h3 className="text-title">
//             Building Tomorrow’s Infrastructure Today,
//           </h3>
//           <h3 className="text-title">
//             {" "}
//             Your Partner in Excellence and Innovation.
//           </h3>
//           <p className="text-descritpion">
//             Crafting the future with innovation, our PMC firm applies
//             cutting-edge technology to diverse projects. We foster partnerships,
//             contribute to national initiatives, and prioritize compliance. Join
//             us in shaping tomorrow’s infrastructure.
//           </p>
//           {/* <button
//             className="text-appointment-btn"
//             type="button"
//             onClick={Footerjs}
//           >
//             <FontAwesomeIcon icon={faCalendarCheck} /> Contact Now
//           </button> */}
//           <a href="#footer" className="text-appointment-btn">
//             <FontAwesomeIcon icon={faContactBook} /> Contact Now
//           </a>
//           <div className="text-stats">
//             <div className="text-stats-container">
//               <p>25+</p>
//               <p>Years in Experience</p>
//             </div>

//             <div className="text-stats-container">
//               <p>400+</p>
//               <p>Sites Completed</p>
//             </div>

//             <div className="text-stats-container">
//               <p>1700+</p>
//               <p>Job Location</p>
//             </div>
//             {/* <div className="text-stats-container">
//               <p>70</p>
//               <p>USOF Sites Completed</p>
//             </div> */}
//           </div>
//         </div>

//         <div className="hero-image-section">
//           <img className="hero-image1" src={Doctor} alt="Doctor" />
//         </div>
//       </div>

//       <div
//         onClick={scrollToTop}
//         className={`scroll-up ${goUp ? "show-scroll" : ""}`}
//       >
//         <FontAwesomeIcon icon={faAngleUp} />
//       </div>
//     </div>
//   );
// }

// export default Hero;


// import React, { useState, useEffect } from "react";
// import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
// import { RxDotFilled } from "react-icons/rx";
// import NavDrop from "./NavDrop";
// import slide1 from "../Assets/sky3.webp";
// import slide2 from "../Assets/tower1.webp";
// import slide3 from "../Assets/sky.webp";
// import slide4 from "../Assets/tower3.webp";
// import slide5 from "../Assets/road.webp";

// const slides = [
//   { url: slide1 },
//   { url: slide2 },
//   { url: slide3 },
//   { url: slide4 },
//   { url: slide5 },
// ];

// // Preload images
// slides.forEach((slide) => {
//   const img = new Image();
//   img.src = slide.url;
// });

// function Slider() {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const prevSlide = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const nextSlide = () => {
//     const isLastSlide = currentIndex === slides.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       nextSlide();
//     }, 5000);

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, [currentIndex]);

//   return (
//     <div id="slider" className="max-w-[3500px] w-full border-0 mb-0 relative">
      
//       <div
//         style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
//         className="w-full h-[600px] bg-center bg-cover duration-2500 ease-in-out  transition-opacity fade"
//       >
//         <div className="relative overflow-hidden w-full h-full">
//           <div className="relative pt-6 pb-4 sm:pb-4 h-full">
//             <div className="relative mx-auto max-w-7xl z-10">
//               <div className="px-4 sm:px-6 lg:px-8">
//                 <div className="lg:text-right text-center">
//                   <h3 className="mb-4 font-medium tracking-wider sm:text-4xl md:text-5xl lg:text-3xl mt-[266px] text-right">
//                     <span className="block text-blue-700"></span>
//                   </h3>
//                   <h2 className="custom-heading text-2xl sm:text-3xl md:text-4xl lg:text-3xl font-extrabold animate-slideInFromRight tracking-tight rounded">
//                     <span className="bg-white p-1 rounded">
//                       Think Innovation
//                     </span>
//                   </h2>
//                   <h2 className="custom-heading text-2xl sm:text-3xl md:text-4xl lg:text-3xl font-extrabold animate-slideInFromRight tracking-tight rounded mt-7">
//                     <span className="bg-white p-1 rounded">Think Veritech</span>
//                   </h2>
//                   <a className="" href="/Projects">
//                     <button className="inline-flex mt-6 items-center justify-center rounded text-sm font-medium text-white bg-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border hover:bg-accent hover:text-accent-foreground h-10 px-3 py-2">
//                       Our Projects
//                     </button>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Navigation Dots */}
//         <div className="flex top-4 justify-center mt-[-60px]">
//           {slides.map((slide, slideIndex) => (
//             <div
//               key={slideIndex}
//               onClick={() => goToSlide(slideIndex)}
//               className={`text-2xl cursor-pointer ${
//                 currentIndex === slideIndex ? "text-white" : "text-black"
//               }`}
//             >
//               <RxDotFilled />
//             </div>
//           ))}
//         </div>

//         {/* Left Arrow */}
//         {/* <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
//           <BsChevronCompactLeft onClick={prevSlide} size={30} />
//         </div> */}

//         {/* Right Arrow */}
//         {/* <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
//           <BsChevronCompactRight onClick={nextSlide} size={30} />
//         </div> */}
//       </div>
//     </div>
//   );
// }

// export default Slider;

import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import slide1 from "../Assets/sky3.webp";
import slide2 from "../Assets/tower1.webp";
import slide3 from "../Assets/sky.webp";
import slide4 from "../Assets/tower3.webp";
import slide5 from "../Assets/road.webp";
import Navbar from "./NavHero";

const slides = [
  { url: slide1 },
  { url: slide2 },
  { url: slide3 },
  { url: slide4 },
  { url: slide5 },
];

// Preload images
slides.forEach((slide) => {
  const img = new Image();
  img.src = slide.url;
});

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex]);

  return (
    <div className="relative">
      <Navbar />
      <div id="slider" className="max-w-[3500px] w-full border-0 mb-0 relative">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="w-full h-[600px] bg-center bg-cover duration-2500 ease-in-out  transition-opacity fade"
        >
          <div className="relative overflow-hidden w-full h-full">
            <div className="relative pt-6 pb-4 sm:pb-4 h-full">
              <div className="relative mx-auto max-w-7xl z-10">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="lg:text-right text-center">
                    <h3 className="mb-4 font-medium tracking-wider sm:text-4xl md:text-5xl lg:text-3xl mt-[266px] text-right">
                      <span className="block text-blue-700"></span>
                    </h3>
                    <h2 className="custom-heading text-2xl sm:text-3xl md:text-4xl lg:text-3xl font-extrabold animate-slideInFromRight tracking-tight rounded">
                      <span className="bg-white p-1 rounded">
                        Think Innovation
                      </span>
                    </h2>
                    <h2 className="custom-heading text-2xl sm:text-3xl md:text-4xl lg:text-3xl font-extrabold animate-slideInFromRight tracking-tight rounded mt-7">
                      <span className="bg-white p-1 rounded">
                        Think Veritech
                      </span>
                    </h2>
                    <a className="" href="/Projects">
                      <button className="inline-flex mt-6 items-center justify-center rounded text-sm font-medium text-white bg-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border hover:bg-accent hover:text-accent-foreground h-10 px-3 py-2">
                        Our Projects
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Navigation Dots */}
          <div className="flex top-4 justify-center mt-[-60px]">
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className={`text-2xl cursor-pointer ${
                  currentIndex === slideIndex ? "text-white" : "text-black"
                }`}
              >
                <RxDotFilled />
              </div>
            ))}
          </div>

          {/* Left Arrow */}
          {/* <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <BsChevronCompactLeft onClick={prevSlide} size={30} />
          </div> */}

          {/* Right Arrow */}
          {/* <div className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <BsChevronCompactRight onClick={nextSlide} size={30} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Slider;
