// import React from "react";
// import Navbar from "../Components/Navbar";
// import Hero from "../Components/Hero";
// import Info from "../Components/Info";
// import About from "../Components/About";
// import Team from "../Components/Team";
// import Footer from "../Components/Footer";
// import ClientsCarousel from "../Components/ClientCard";
// import NavDrop from "../Components/NavDrop";
// // import ImpactOfVeritech from "../Components/ImpactVeritech";

// function Home() {
//   return (
//     <div className="home-section">
//       <Navbar />
//       {/* <NavDrop/> */}
//       <Hero />
//       <Info />
//       <About />
//      <ClientsCarousel/>
//       <Team />
//       <Footer />
//     </div>
//   );
// }

// export default Home;
import React, { Suspense, lazy } from "react";
// import Navbar from "../Components/Navbar";
// import Hero from "../Components/Hero";
// import Info from "../Components/Info";
// import About from "../Components/About";
// import Team from "../Components/Team";
// import Footer from "../Components/Footer";
// import ClientsCarousel from "../Components/ClientCard";
// import NavDrop from "../Components/NavDrop";

 import Navbar from "../Components/Navbar";
 import Hero from "../Components/Hero";
const Info = lazy(() => import("../Components/Info"));
const About = lazy(() => import("../Components/About"));
const Team = lazy(() => import("../Components/Team"));
const Footer = lazy(() => import("../Components/Footer"));
const ClientsCarousel = lazy(() => import("../Components/ClientCard"));
const NavDrop = lazy(() => import("../Components/NavDrop"));
// const ImpactOfVeritech = lazy(() => import("../Components/ImpactVeritech"));

function Home() {
  return (
    <div className="home-section">
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        {/* <NavDrop/> */}
        <Hero />
        <Info />
        <About />
        <ClientsCarousel />
        <Team />
        <Footer />
      </Suspense>
    </div>
  );
}

export default Home;
