import React, { useState } from "react";
import styled from "styled-components";
import { FiSearch } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const [companyDropdown, setCompanyDropdown] = useState(false);
  const [businessDropdown, setBusinessDropdown] = useState(false);
  const [projectsDropdown, setProjectsDropdown] = useState(false);

  return (
    <Nav>
    <NavItems>
        <NavItem>
          <NavLink href="/">Home</NavLink>
        </NavItem>
        <NavItem
          onMouseEnter={() => setCompanyDropdown(true)}
          onMouseLeave={() => setCompanyDropdown(false)}
        >
          Company <Arrow>▼</Arrow>
          {companyDropdown && (
            <Dropdown>
              <DropdownItem>
                <NavLink href="/Company">About Company</NavLink>
              </DropdownItem>
              <DropdownItem>
                <HashLink to="/#services">Our Services</HashLink>
              </DropdownItem>
            </Dropdown>
          )}
        </NavItem>
        <NavItem
          onMouseEnter={() => setBusinessDropdown(true)}
          onMouseLeave={() => setBusinessDropdown(false)}
        >
          Our Business <Arrow>▼</Arrow>
          {businessDropdown && (
            <Dropdown>
              <DropdownItem>
                <NavLink href="/Projects">Company Overview</NavLink>
              </DropdownItem>
              <DropdownItem>
                <HashLink to="/#team">Team</HashLink>
              </DropdownItem>
              <DropdownItem>
                <HashLink to="/#clients">Clients</HashLink>
              </DropdownItem>
            </Dropdown>
          )}
        </NavItem>
        <NavItem
          onMouseEnter={() => setProjectsDropdown(true)}
          onMouseLeave={() => setProjectsDropdown(false)}
        >
          Our Projects <Arrow>▼</Arrow>
          {projectsDropdown && (
            <Dropdown>
              <DropdownItem>
                <NavLink href="/TotalSites">Total Completed Sites</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink href="/AirtelProject">About Airtel Project</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink href="/IndusProject">
                  About Indus Tower Project
                </NavLink>
              </DropdownItem>
            </Dropdown>
          )}
        </NavItem>
        <NavItem>
          <NavLink href="/">Investors</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/">Media</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/">Career</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Contact">Contact Us</NavLink>
        </NavItem>
      </NavItems>
      <SearchIconContainer>
        <FiSearch size={23} color="white" /> {/* Example search icon */}
      </SearchIconContainer>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  background: rgba(0, 0, 0, 0.70);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  z-index: 1000;
  @media (max-width: 768px) {
    display: none;
  }
`;
const NavItems = styled.div`
  display: flex;
`;

const SearchIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
`;

const NavItem = styled.div`
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background: #444;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  top: 100%;
  left: 0;
  background: white;
  color: black;
width: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  @media (max-width: 768px) {
    display: none;
  }
`;

const DropdownItem = styled.div`
  padding: 0.5rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: pointer;

  &:hover {
    background: #ddd;
  }
`;

const Arrow = styled.span`
  font-weight: normal; /* Make the arrow less bold */
  font-size: 0.8em; /* Adjust the size as needed */
  margin-left: 0.5em; /* Add some space between the text and the arrow */
  color: #ccc; /* Change the color to match the text */
`;